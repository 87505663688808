@tailwind base;
@tailwind components;
@tailwind utilities;


:root{
--blue-500: '#81D8F7';
--blue-600: '#00E3F0';
--blue-900: '#01244A';
--blue-999: '#01091f';      
        
--gray-100: '#E1E1E6';
--gray-200: '#C4C4CC';
--gray-300: '#8D8D99';
--gray-500: '#323238';
--gray-600: '#29292E';
--gray-700: '#121214';
--gray-900: '#09090A';
}

body {
  @apply bg-blue-999 text-gray-100;
  -webkit-font-smoothing: antialiased;
}

input[type=range] {
  height: 38px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 50%;
  border-radius: 15px;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 50%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #ffffffde;
  border-radius: 5px;
  border: 1px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 15px;
  width: 15px;
  border-radius:10px;
  background: #f8f7fa;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #f8f7fa;
}
input[type=range]::-moz-range-track {
  width: 50%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #f8f7fa;
  border-radius: 5px;
  border: 1px solid #000000;
}
