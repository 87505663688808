
input[type=range] {
    height: 34px;
    margin: 10px 0;
    width: 100%;
    background: transparent;

  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    box-shadow: 1px 1px 1px var(--gray-100);
    background: var(--gray-100);
    border-radius: 1px;
    border: 1px solid var(--gray-100);
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 3px 3px 3px var(--gray-300);;
    border: 2px solid var(--gray-100);
    height: 23px;
    width: 23px;
    border-radius: 23px;
    background: var(--gray-500);
    cursor: pointer;
    margin-top: -7px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--gray-600);
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    box-shadow: 1px 1px 1px var(--gray-100);
    background: var(--gray-600);
    border-radius: 1px;
    border: 1px solid var(--gray-100);
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 3px 3px 3px var(--gray-300);;
    border: 2px solid var(--gray-100);
    height: 23px;
    width: 23px;
    border-radius: 23px;
    background: var(--gray-500);
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: var(--gray-600);
    border: 1px solid var(--gray-100);
    border-radius: 2px;
    box-shadow: 1px 1px 1px var(--gray-100);
  }
  input[type=range]::-ms-fill-upper {
    background: var(--gray-600);
    border: 1px solid var(--gray-100);
    border-radius: 2px;
    box-shadow: 1px 1px 1px var(--gray-100);
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 3px 3px 3px var(--gray-300);;
    border: 2px solid var(--gray-100);
    height: 23px;
    width: 23px;
    border-radius: 23px;
    background: var(--gray-500);
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: var(--gray-600);
  }
  input[type=range]:focus::-ms-fill-upper {
    background: var(--gray-600);
  }

  .videoPage{
    display: flex;
    margin-top: 3rem/* 48px */;
    align-items: center;
    justify-content: center;
  }

  .videoPageLevel1{
    flex-direction: row;
    justify-content: center;
    width: 100vw;
  }

  .videoSpinner{
    display: flex;
    justify-content: center;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-size: 1.875rem/* 30px */;
    line-height: 2.25rem/* 36px */;
    z-index: 20;
  }

  .videoSpinnerComplement{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -4%;
  }

  .videoIconControl{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px ;
    height: 3rem/* 48px */;
    width: 7rem/* 112px */;
    padding: 0.25rem/* 4px */;
    margin-right: 0.5rem/* 8px */;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(1 9 31 / var(--tw-text-opacity));
    text-align: center;

  }

  .videoIconControl:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(1 9 31 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
    border-width: 1px;
    cursor: pointer;
    animation-duration: 400ms;
  }

  @media (max-width: 851px) {
    .videoIconControl {
      width: 4rem;
      font-size: "0px";
      justify-content: center;
      }
  }

  @media (min-width: 852px) {
    .videoIconControl {
      width: 7rem/* 112px */;
      font-size: "16px";
      justify-content: flex-end;
    }
  }

 
  .videoCommand{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    font-size: 16px;
    --tw-text-opacity: 1;
    color: rgb(1 9 31 / var(--tw-text-opacity));
    margin-right: 0.5rem
  }

  .videoCommand:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(1 9 31 / var(--tw-bg-opacity));
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
    border-width: 1px;
    cursor: pointer;
    animation-duration: 400ms;
  }
